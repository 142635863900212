
import Paging from "@/components/Paging.vue";
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
@Component({
  components: { Paging,Breadcrumbs },
})
export default class TrainingFileDetails extends Vue {
  systemInfo:any;
  zzlApi: any;
  tableData: any = [];
  tempOfflineFrames: any = [];
  get offlineId() {
    return this.$route.query.id;
  }
  learnDetailTable: any = {
    pageNum: 1,
    pageSize: 20,
    total: 0,
  };
  offlineDetails: any = {};

  /* 3-大纲明细列表  */
  async getData() {
    let params = {
      pageNum: this.learnDetailTable.pageNum,
      pageSize: this.learnDetailTable.pageSize,
      offlineId: this.offlineId,
    };
    const res = await this.zzlApi.member.GetMemberTriningDetails(params);
    if (res.code === 0) {
      this.learnDetailTable.total = res.data.importantRecords.count;
      this.tableData = res.data.importantRecords.list;
      this.tempOfflineFrames = res.data.tempOfflineFrames;
    } else {
      this.$message.error(res.msg);
    }
  }
  initData() {
    let storageData = window.sessionStorage.getItem("offlineDetails");
    if (storageData) {
      this.offlineDetails = JSON.parse(storageData);
      this.$set(this.offlineDetails,"date",[this.offlineDetails.classDateBegin + "",this.offlineDetails.classDateEnd + ""])
    }
  }
  mounted() {
    this.initData();
    this.getData();
  }
}
